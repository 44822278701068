/* Bounce To Right */
$foundry_blue: #00a8df;

@mixin bounce-to-right {
	@include hacks();
	position: relative;
	@include prefixed(transition-property, color);
	@include prefixed(transition-duration, $slowDuration);

	&:before {
		content: "";
		position: absolute;
		z-index: -1;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: $foundry_blue;
		@include prefixed(transform, scaleX(0));
		@include prefixed(transform-origin, 0 50%);
		@include prefixed(transition-property, transform);
		@include prefixed(transition-duration, $slowDuration);
		@include prefixed(transition-timing-function, ease-out);
	}

	&:hover,
	&:focus,
	&:active {
		color: white;

		&:before {
			@include prefixed(transform, scaleX(1));
			@include prefixed(transition-timing-function, cubic-bezier(.52,1.64,.37,.66));
		}
	}
}



@mixin bounce-to-right-person {
	@include hacks();
	position: relative;
	@include prefixed(transition-property, color);
	@include prefixed(transition-duration, $slowDuration);
	display: block;

	&:before {
		content: "";
		position: absolute;
		z-index: -1;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: $foundry_blue;
		@include prefixed(transform, scaleX(0));
		@include prefixed(transform-origin, 0 50%);
		@include prefixed(transition-property, transform);
		@include prefixed(transition-duration, $slowDuration);
		@include prefixed(transition-timing-function, ease-out);
		
	}

	&:hover,
	&:focus,
	&:active {
		color: white;
		
		.person-biography {
			display: block;
			visibility: visible;
			background-color: rgba(0, 168, 233, 0.6);
			width: 100%;
			height: 900px;
		}

		&:before {
			@include prefixed(transform, scaleX(1));
			@include prefixed(transition-timing-function, cubic-bezier(.52,1.64,.37,.66));
		}
	}
}
