fieldset {border: 0; margin: 30px 0 0 0; padding: 0;}

label {
	font-family: $foundry_baskerville;
	color: $foundry_grey;
    font-size: 0.85em;
    line-height: 2em;
    margin: 0.25em 1em 0.25em 0;
}

.form-field {
	background: #eee; 
	margin-bottom: 20px; 
	padding: 15px; 
	text-align: left;
}
.form-field:nth-child(6) {
    height: 300px;
}

perch:input:placeholder-shown {color: #000;}
::-webkit-input-placeholder {color: #000;}
:-moz-placeholder { /* older Firefox*/ color: #000;}
::-moz-placeholder { /* Firefox 19+ */ color: #000;} 
:-ms-input-placeholder {color: #000;}

textarea {
    background: transparent;
    border: 0;
    padding: 0;
    font-family: $foundry_regular;
    line-height: 2em;
    color: #000;
    width: 100%;
    height: 300px;
}

input {
    background: transparent;
    border: 0;
    padding: 0;
    margin: 0.25em 0;
    font-family: $foundry_regular;
    color: #000;
    width: 100%;
}

input[value="Send"] {
    text-transform: uppercase;
    font-family: $foundry_extra_bold;
}

button {
	align-items: center;
    pointer-events: auto;
    background: #00a8df;
    /* overflow: auto; */
    padding: 20px 30px;
    margin-top: 25px;
    border: 1px solid #fff;
    p {
        text-transform: uppercase;
        line-height: 34px;
        padding: 0 0 0 0;
        margin: 0 0 0 0;
        color: #fff;
    }
    img {
        width: auto;
        margin-left: 10px;
    }
}