#blog-masthead.masthead {
	background: black url("/img/backgrounds/general-background.jpg") no-repeat top center; 
	background-size: cover;
	.masthead-content {width: 90%;}
}

#recent-posts-index {
	margin-top: 60px;
	margin-bottom: 60px;
	.blog {
		h1, h3 {
			font-family: $foundry_extra_bold;
			text-transform: uppercase;
			font-size: 1.5em;
			line-height: 1.1em;
			margin-bottom: 0;
			a {
				font-family:inherit; 
				color: #000
			}
		}
		article {width: 100%; margin-bottom: 60px;}
		flex-wrap: wrap;
		.button-next {@include blue-button-small;}
		p, a {
	        color: $foundry_grey;
	        font-family: $foundry_baskerville;
	    }
	}

	.blog-index-image {
		img {width: 100%;}
		margin: 20px auto;
		max-height: 400px;
		overflow: hidden;
	}
	.older-newer {@include black-button-small; margin-right: 15px;}
	.older-newer p {font-family: $foundry_extra_bold;}
	.older-newer:hover {@include rollover-button-small;}
}

#posts {
	margin: 120px auto 60px auto;
	max-width: 800px;
	.blog {
		h1 {
			font-family: $foundry_extra_bold;
			text-transform: uppercase;
			font-size: 1.8em;
			line-height: 1.2em;
			margin-bottom: 0;
			a {
				font-family: $foundry_baskerville;; 
				color: #000;
			}
		}
		img {width: 100%; margin: 20px auto;}
		.blog-post-video {width: 100%; margin: 20px auto;}
		article {width: 100%; margin-bottom: 60px;}
		flex-wrap: wrap;
		.button-next {@include black-button-small;}
		.button-next:hover {@include rollover-button-small;}
		.blog-post-body p:first-of-type {font-size: 1.2em; line-height: 1.8em;}
		p {
	        color: $foundry_grey;
	        font-family: $foundry_baskerville;;
	        margin-top: 0;
	    }
	    a {
	        color: $foundry_blue;
	        font-family: inherit;
	    }
	    li {
	    	font-size: 0.9em;
		    line-height: 2.2em;
		}
	}
}

.blog-post-cats {padding: 0; margin-bottom: 30px;}
.blog-post-cats li {list-style: none; margin-right: 15px;}

.author_image {align-self: center; width: 100%;}
.author_name {padding-left: 0;}
.blog-post-author {
	flex-direction: column;
	margin: 60px 0;
	background: black url("/img/backgrounds/general-background.jpg") no-repeat center left;
    background-size: cover;
    z-index: 100;
}
.author_name {
	width: 90%;
    padding: 30px 5%;
    h2 {font-size: 1.5em; color: #ffffff;}
    p {color: #ffffff; font-family: $foundry_baskerville; font-size: 1em; line-height: 1.4em;}
    .rule img {margin-bottom: 12px;}
}

.attractor-panel {
	background: black url("/img/backgrounds/background-image-1.jpg") no-repeat center left;
	padding: 14px 20px 22px 20px;
	margin-bottom: 12px;
}

.attractor-panel h3 {
	color: #00a8df;
	font-family: "Gotham Medium Condensed",sans-serif,arial,sans-serif;
	font-size: 1.3em;
	text-transform: uppercase;
	padding-bottom: 2px;
	margin-bottom: 0;
}

.attractor-panel p {
	color: #FFF !important;
	font-family: "Gotham Medium Book",sans-serif,arial,sans-serif !important;
	margin-top: 0px !important;
	padding-bottom: 0;
	margin-bottom: 0;
	line-height: 1.6em;
	font-size: 0.9em;
}


@media screen and (min-width: 800px) {
	#recent-posts-index {
		.blog {
			article {width: 47.5%;}
			article:nth-child(odd) {margin-right: 5%;}
			max-width: 800px;
			margin: 0 auto;
		}
	}
}

@media screen and (min-width: 450px) {
	.author_image {width: 50%; margin: 20px auto 0 5%;}
}

@media screen and (min-width: 650px) {
	.blog-post-author {flex-direction: row;}
	.author_name {padding-left: 20px;}
	.author_image {width: 50%; margin: 0;}
}

@media screen and (min-width: 800px) {
	.author_image {width: 30%;}
}